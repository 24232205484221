import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'next-back-button',
  templateUrl: './next.back.button.component.html',
  styleUrls: ['./next.back.button.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatProgressSpinnerModule,
  ],
})
export class NextBackButtonComponent implements OnInit {
  @Output() nextButton = new EventEmitter<boolean>();
  @Output() backButton = new EventEmitter<boolean>();
  @Output() middleButton = new EventEmitter<boolean>();
  @Input()
  nextLoading: any;
  @Input()
  isNextButtonDisabled: boolean;
  @Input()
  isMiddleButtonDisabled: boolean = false;
  @Input()
  hideBackButton: boolean = false;
  @Input()
  hideMiddleButton: boolean = true;
  @Input()
  hideNextButton: boolean = false;
  @Input()
  styleType: number = 0;
  @Input()
  middleButtonText: string;
  @Input()
  nextButtonText: string = 'cabinet.profile.buttons.next';
  @Input()
  backButtonText: string = 'cabinet.profile.buttons.back';
  @Input() hiddenIcon = false;

  constructor() {}

  ngOnInit(): void {}

  handleMiddleButton() {
    this.middleButton.emit(true);
  }

  handlePreviousStep() {
    this.backButton.emit(true);
  }

  handleNextStep() {
    this.nextButton.emit(true);
  }
}
