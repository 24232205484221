<div class="button-container">
  @if (!hideBackButton) {
  <button
    [disabled]="nextLoading"
    mat-raised-button
    (click)="handlePreviousStep()"
  >
    {{ backButtonText | translate }}

    <mat-icon iconPositionStart>navigate_before</mat-icon>
  </button>
  } @if (!hideMiddleButton) {
  <button
    [disabled]="nextLoading || isMiddleButtonDisabled"
    mat-raised-button
    (click)="handleMiddleButton()"
    [class.align-left-middle]="styleType === 0"
    [class.align-right-middle]="styleType === 1 || styleType === 2"
  >
    {{ middleButtonText | translate }}
    @if(!hiddenIcon){
    <mat-icon iconPositionStart>navigate_before</mat-icon>
    }
  </button>
  } @if(!hideNextButton) { @if (nextLoading) {
  <button
    type="submit"
    color="primary"
    disabled
    mat-raised-button
    [class.align-right]="styleType === 0"
    [class.align-right-next]="styleType === 1"
    [class.align-end-next]="styleType === 2"
    disabled="true"
  >
    <mat-spinner diameter="18"></mat-spinner>
  </button>
  } @else {
  <button
    [class.align-right]="styleType === 0"
    [class.align-right-next]="styleType === 1"
    [class.align-end-next]="styleType === 2"
    type="submit"
    color="primary"
    mat-raised-button
    [disabled]="isNextButtonDisabled"
    (click)="handleNextStep()"
  >
    {{ nextButtonText | translate }}
    @if(!hiddenIcon){
    <mat-icon iconPositionEnd>navigate_next</mat-icon>
    }
  </button>
  } }
</div>
