import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function confirmedValidator(controlName: string, matchingControlName: string, ifNotEmpty?: string): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const control: AbstractControl | null = formGroup.get(controlName);
    const controlForEmptyCheck: AbstractControl | null = formGroup.get(ifNotEmpty);
    const matchingControl: AbstractControl | null = formGroup.get(matchingControlName);

    if(controlForEmptyCheck?.value === ''){
      return null;
    }

    if (control.value && !matchingControl.value) {
      matchingControl?.setErrors({ required: true });
      return { required: true };
    }

    if (control && matchingControl && control.value !== matchingControl.value && matchingControl.value !== '') {
      matchingControl.setErrors({ confirmedValidator: true });
      return { confirmedValidator: true };
    } else {
      matchingControl?.setErrors(null);
      matchingControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });

      return null;
    }
  };
}
