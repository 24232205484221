import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordMatchValidator(
  firstName: string,
  lastName: string,
  email: string,
  password: string
): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const firstNameControl: AbstractControl | null = formGroup.get(firstName);
    const lastNameControl: AbstractControl | null = formGroup.get(lastName);
    const emailControl: AbstractControl | null = formGroup.get(email);
    const passwordControl: AbstractControl | null = formGroup.get(password);
    passwordControl.valueChanges.subscribe((value) => {
      if (value) {
        if (passwordControl && firstNameControl.value.toLowerCase().includes(passwordControl?.value?.toLowerCase())) {
          passwordControl?.setErrors({ containsFirstName: true });
          return { containsFirstName: true };
        }
        if (passwordControl && lastNameControl.value.toLowerCase().includes(passwordControl?.value?.toLowerCase())) {
          passwordControl?.setErrors({ containsLastName: true });
          return { containsLastName: true };
        }
        if (passwordControl && emailControl.value.toLowerCase().includes(passwordControl?.value?.toLowerCase())) {
          passwordControl?.setErrors({ containsEmail: true });
          return { containsEmail: true };
        }
      }
      return null;
    });

    firstNameControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    lastNameControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    emailControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    passwordControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });

    return null;
  };
}
